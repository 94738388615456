import React, { useCallback, useState } from "react";
import QRCode from "react-qr-code";

const QRGenComp = () => {
  const [data, setdata] = useState(null);
  const [name, setname] = useState(null);
  const [contact, setcontact] = useState(null);

  const submitData = useCallback(() => {
    if (name && contact) {
      //   console.log(`Name: ${name} | Contact: ${contact}`);
      let info = [];
      info = JSON.stringify([...info, { name: name, "contact:": contact }]);
      console.log(info);
      setdata(info);
    } else {
      alert("Name and contact are required");
    }
  }, [name, contact]);

  return (
    <div className="_qr-gen">
      {data ? <QRCode className="_qrcode" value={data} /> : null}
      <label>Name*</label>
      <input
        type="text"
        placeholder="Name*"
        onChange={(e) => setname(e.target.value)}
      />
      <label>Contact Number*</label>
      <input
        type="number"
        placeholder="Contact Number*"
        onChange={(e) => setcontact(e.target.value)}
      />
      <input type="submit" value="Submit" onClick={() => submitData()} />
    </div>
  );
};

export default QRGenComp;
