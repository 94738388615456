import React from "react";
import QRScannerComp from "../components/QRScannerComp";

const QRScanner = () => {
  return (
    <div className="screen">
      <QRScannerComp />
    </div>
  );
};

export default QRScanner;
