import React from "react";
import QRGenComp from "../components/QRGenComp";

const QRGenerator = () => {
  return (
    <div className="screen">
      <QRGenComp />
    </div>
  );
};

export default QRGenerator;
