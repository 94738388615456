import React, { useRef, useState } from "react";
import Webcam from "react-webcam";

const WebcamComp = () => {
  const webcamRef = useRef(null);
  const [image, setimage] = useState(null);
  const [toggle, settoggle] = useState(true);

  const videoConstraints = {
    // facingMode: { exact: "environment" }, // doesn't work easilly on desktop
    facingMode: "environment",
  };

  const captureImage = () => {
    const captured = webcamRef.current.getScreenshot({
      width: 320,
      height: 240,
    });
    if (captured) {
      setimage(captured);
      settoggle(false);
    }
  };

  return (
    <div>
      {toggle ? (
        <Webcam
          ref={webcamRef}
          audio={false}
          screenshotFormat={"image/jpeg"}
          videoConstraints={videoConstraints}
        />
      ) : null}
      <br />
      <button onClick={() => captureImage()}>Capture</button>
      <button onClick={() => settoggle(false)}>Stop Camera</button>
      <button onClick={() => settoggle(true)}>Start Camera</button>
      <br />
      {image ? <img src={image} alt="Capture" /> : null}
    </div>
  );
};

export default WebcamComp;
