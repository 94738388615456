import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Home from "./screens/Home";
import Camera from "./screens/Camera";
import QRGenerator from "./screens/QRGenerator";
import QRScanner from "./screens/QRScanner";
import "./sass/App.scss";

const App = () => {
  return (
    <div className="App">
      <Router>
        <nav>
          <Link to="/">Home</Link>
          <Link to="/camera">Camera</Link>
          <Link to="/qrgenerator">QR Generator</Link>
          <Link to="/qrscanner">QR Scanner</Link>
        </nav>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/camera" exact component={Camera} />
          <Route path="/qrgenerator" exact component={QRGenerator} />
          <Route path="/qrscanner" exact component={QRScanner} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
