import React from "react";
import MediaRecorder from "../components/MediaRecorder";
const Home = () => {
  return (
    <div className="home screen">
      <p>Media Recorder Demo</p>
      <MediaRecorder />
    </div>
  );
};

export default Home;
