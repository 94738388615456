import React, { useState } from "react";
import QrReader from "react-qr-reader";

const QRScannerComp = () => {
  const [result, setresult] = useState("No Result.");

  const handleScan = (data = null) => {
    if (data) {
      setresult(data);
    }
  };

  return (
    <div className="_qr-scanner">
      <p>QR Code Scanner</p>
      <QrReader
        delay={300}
        onError={(err) => console.error(err)}
        onScan={(data) => handleScan(data)}
        // style={{ width: "100%" }}
      />
      <p>{result}</p>
    </div>
  );
};

export default QRScannerComp;
