import React, { useEffect } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { ErrorBoundary } from "react-error-boundary";

const ErrorFallback = () => {
  return <p>Not supported.</p>;
};

const MediaRecorder = () => {
  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({ audio: true });

  useEffect(() => {
    if (mediaBlobUrl) {
      console.log(mediaBlobUrl);
    }
  }, [mediaBlobUrl]);
  return (
    <ErrorBoundary fallback={ErrorFallback}>
      <div className="_media-recorder">
        <p>Status: {status}</p>
        <button onClick={startRecording}>Start Recording</button>
        <button onClick={stopRecording}>Stop Recording</button>
        <audio src={mediaBlobUrl} controls autoPlay />
        {mediaBlobUrl ? (
          <a href={mediaBlobUrl} download>
            Download Audio
          </a>
        ) : null}
      </div>
    </ErrorBoundary>
  );
};

export default MediaRecorder;
