import React from "react";
import WebcamComp from "../components/WebcamComp";

const Camera = () => {
  return (
    <div className="screen">
      <p>Camera</p>
      <WebcamComp />
    </div>
  );
};

export default Camera;
